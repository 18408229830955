import { Injectable } from '@angular/core';
import { TranslationKey } from '../models/translation.model';
import { ApiService } from '@capturum/api';

@Injectable({
  providedIn: 'root',
})
export class TranslationKeyService extends ApiService<TranslationKey> {
  protected endpoint = 'translation-key';
}
