import { Entity } from '@core/enums/entity.enum';
import { TranslateService } from '@ngx-translate/core';
import { FilterType, TableColumn } from '@capturum/ui/api';
import { Component, Injector, OnInit } from '@angular/core';
import { Translation } from '../../models/translation.model';
import { DxpBaseListComponent } from '@core/base/dxp-base-list.class';
import { TranslationKeyService } from '../../services/translation.service';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { LocaleApiModel as Locale, LocaleService } from '@capturum/complete';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { EntityConfig } from '@core/models/entity-config.model';

@Component({
  selector: 'app-translation',
  templateUrl: './translation-list.component.html',
})
export class TranslationListComponent extends DxpBaseListComponent<Translation> implements OnInit {
  public entityConfig: EntityConfig = {
    name: Entity.translation,
  };

  constructor(
    public injector: Injector,
    public apiService: TranslationKeyService,
    public translateService: TranslateService,
    public notificationService: NotificationService,
    public layoutUtilsService: LayoutUtilsService,
    private localeService: LocaleService,
  ) {
    super(injector, translateService, notificationService, layoutUtilsService);

    this.includes = ['module'];
    this.columns = this.getColumns();
  }

  public ngOnInit(): void {
    this.loadTableDataCallback = (data) => {
      const adjustedItems = data?.map((item) => {
        const transformedTranslations = {};

        item.translations.forEach((element) => {
          transformedTranslations[element.locale_id] = element.translation;
        });

        item.translations = transformedTranslations;

        return item;
      });

      this.defaultLoadTableCallBackLogic(adjustedItems);
    };

    this.localeService.index().subscribe(({ data }) => {
      return this.setLocales(data);
    });

    super.ngOnInit();
  }

  private getColumns(): TableColumn[] {
    return [
      {
        field: 'key',
        header: this.translateService.stream('translation.overview.label'),
        filterable: true,
        filterType: FilterType.INPUT,
      },
      {
        field: 'module.name',
        header: this.translateService.stream('translation.overview.module'),
        filterable: true,
        filterType: FilterType.INPUT,
      },
    ];
  }

  private setLocales(locales: Locale[]): void {
    const defaultColumns: TableColumn[] = this.getColumns();

    locales.forEach((locale) => {
      const column = {
        field: `translations.${locale.id}`,
        header: locale.name,
        filterable: false,
        sortable: false,
      };

      defaultColumns.push(column);
    });

    this.columns = TableColumn.getColumns(defaultColumns);
  }
}
